import * as React from "react";
import Arrow from "../images/arrow.inline.svg";
import * as formStyles from "./form.module.css";

class FormPartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      title: "",
      email: "",
      phone: "",
      company: "",
      country: "AD",
      receiveInformationEmails: false,
      LeadSource: "Website",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    window.rudderanalytics.identify(
      "userid",
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        title: this.state.title,
        company: this.state.company,
        email: this.state.email,
        phone: this.state.phone,
        country: this.state.country,
        receiveInformationEmails: this.state.receiveInformationEmails,
        LeadSource: this.state.LeadSource,
      },
      {
        integrations: {
          Salesforce: true,
        },
      }
    );
    window.rudderanalytics.reset();

    // temp prevent form submission
    event.preventDefault();

    // add the loading class for the animation
    document.querySelector(".form-submit").classList.add("loading");

    // wait 2s for form to send the data, then redirect to the thank you page
    setTimeout(function submitForm() {
      document.querySelector("#form").submit();
      window.location.href = "/thank-you-partner/";
    }, 2000);
  }

  render() {
    return (
      <div className={`form flow ${formStyles.form}`}>
        <h2>Let's Talk</h2>
        <p>
          If you'd like to know more about how you or your organization can help
          support a climate-positive food future, fill out the form below.
        </p>
        <form
          className="flow"
          id="form"
          method="post"
          onSubmit={this.handleSubmit}
        >
          <fieldset className={`form-col--2 ${formStyles.formCol2}`}>
            <div className={`input-group ${formStyles.inputGroup}`}>
              <label htmlFor="firstName">First name</label>
              <input
                value={this.state.firstName}
                onChange={this.handleInputChange}
                id="firstName"
                name="firstName"
                type="text"
                autoComplete="given-name"
                required
              />
            </div>
            <div className={`input-group ${formStyles.inputGroup}`}>
              <label htmlFor="lastName">Last name</label>
              <input
                value={this.state.lastName}
                onChange={this.handleInputChange}
                id="lastName"
                name="lastName"
                type="text"
                autoComplete="family-name"
                required
              />
            </div>
          </fieldset>
          <div className={`input-group ${formStyles.inputGroup}`}>
            <label htmlFor="title">Title</label>
            <input
              value={this.state.title}
              onChange={this.handleInputChange}
              id="title"
              name="title"
              type="text"
              required
            />
          </div>
          <div className={`input-group ${formStyles.inputGroup}`}>
            <label htmlFor="company">Farm/Company Name</label>
            <input
              value={this.state.company}
              onChange={this.handleInputChange}
              id="company"
              name="company"
              type="text"
              autoComplete="organization"
              required
            />
          </div>
          <div className={`input-group ${formStyles.inputGroup}`}>
            <label htmlFor="email">Email</label>
            <input
              value={this.state.email}
              onChange={this.handleInputChange}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
            />
          </div>
          <div className={`input-group ${formStyles.inputGroup}`}>
            <label htmlFor="phone">Phone</label>
            <input
              value={this.state.phone}
              onChange={this.handleInputChange}
              id="phone"
              name="phone"
              type="tel"
              placeholder="e.g. +1(123)123-1234"
              autoComplete="tel"
              required
            />
          </div>
          <div className={`input-group ${formStyles.inputGroup}`}>
            <label htmlFor="country">Country</label>
            <select
              value={this.state.country}
              onChange={this.handleInputChange}
              id="country"
              name="country"
              autoComplete="country-name"
              required
            >
              <option value="AD">Andorra</option>
              <option value="AE">United Arab Emirates</option>
              <option value="AF">Afghanistan</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AI">Anguilla</option>
              <option value="AL">Albania</option>
              <option value="AM">Armenia</option>
              <option value="AO">Angola</option>
              <option value="AQ">Antarctica</option>
              <option value="AR">Argentina</option>
              <option value="AT">Austria</option>
              <option value="AU">Australia</option>
              <option value="AW">Aruba</option>
              <option value="AX">Aland Islands</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BB">Barbados</option>
              <option value="BD">Bangladesh</option>
              <option value="BE">Belgium</option>
              <option value="BF">Burkina Faso</option>
              <option value="BG">Bulgaria</option>
              <option value="BH">Bahrain</option>
              <option value="BI">Burundi</option>
              <option value="BJ">Benin</option>
              <option value="BL">Saint Barthélemy</option>
              <option value="BM">Bermuda</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BO">Bolivia, Plurinational State of</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BR">Brazil</option>
              <option value="BS">Bahamas</option>
              <option value="BT">Bhutan</option>
              <option value="BV">Bouvet Island</option>
              <option value="BW">Botswana</option>
              <option value="BY">Belarus</option>
              <option value="BZ">Belize</option>
              <option value="CA">Canada</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CD">Congo, the Democratic Republic of the</option>
              <option value="CF">Central African Republic</option>
              <option value="CG">Congo</option>
              <option value="CH">Switzerland</option>
              <option value="CI">Cote d'Ivoire</option>
              <option value="CK">Cook Islands</option>
              <option value="CL">Chile</option>
              <option value="CM">Cameroon</option>
              <option value="CN">China</option>
              <option value="CO">Colombia</option>
              <option value="CR">Costa Rica</option>
              <option value="CU">Cuba</option>
              <option value="CV">Cape Verde</option>
              <option value="CW">Curaçao</option>
              <option value="CX">Christmas Island</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DE">Germany</option>
              <option value="DJ">Djibouti</option>
              <option value="DK">Denmark</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="DZ">Algeria</option>
              <option value="EC">Ecuador</option>
              <option value="EE">Estonia</option>
              <option value="EG">Egypt</option>
              <option value="EH">Western Sahara</option>
              <option value="ER">Eritrea</option>
              <option value="ES">Spain</option>
              <option value="ET">Ethiopia</option>
              <option value="FI">Finland</option>
              <option value="FJ">Fiji</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FR">France</option>
              <option value="GA">Gabon</option>
              <option value="GB">United Kingdom</option>
              <option value="GD">Grenada</option>
              <option value="GE">Georgia</option>
              <option value="GF">French Guiana</option>
              <option value="GG">Guernsey</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GL">Greenland</option>
              <option value="GM">Gambia</option>
              <option value="GN">Guinea</option>
              <option value="GP">Guadeloupe</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="GR">Greece</option>
              <option value="GS">
                South Georgia and the South Sandwich Islands
              </option>
              <option value="GT">Guatemala</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HM">Heard Island and McDonald Islands</option>
              <option value="HN">Honduras</option>
              <option value="HR">Croatia</option>
              <option value="HT">Haiti</option>
              <option value="HU">Hungary</option>
              <option value="ID">Indonesia</option>
              <option value="IE">Ireland</option>
              <option value="IL">Israel</option>
              <option value="IM">Isle of Man</option>
              <option value="IN">India</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="IQ">Iraq</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IS">Iceland</option>
              <option value="IT">Italy</option>
              <option value="JE">Jersey</option>
              <option value="JM">Jamaica</option>
              <option value="JO">Jordan</option>
              <option value="JP">Japan</option>
              <option value="KE">Kenya</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="KH">Cambodia</option>
              <option value="KI">Kiribati</option>
              <option value="KM">Comoros</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="KW">Kuwait</option>
              <option value="KY">Cayman Islands</option>
              <option value="KZ">Kazakhstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LB">Lebanon</option>
              <option value="LC">Saint Lucia</option>
              <option value="LI">Liechtenstein</option>
              <option value="LK">Sri Lanka</option>
              <option value="LR">Liberia</option>
              <option value="LS">Lesotho</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="LV">Latvia</option>
              <option value="LY">Libyan Arab Jamahiriya</option>
              <option value="MA">Morocco</option>
              <option value="MC">Monaco</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="ME">Montenegro</option>
              <option value="MF">Saint Martin (French part)</option>
              <option value="MG">Madagascar</option>
              <option value="MK">
                Macedonia, the former Yugoslav Republic of
              </option>
              <option value="ML">Mali</option>
              <option value="MM">Myanmar</option>
              <option value="MN">Mongolia</option>
              <option value="MO">Macao</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MS">Montserrat</option>
              <option value="MT">Malta</option>
              <option value="MU">Mauritius</option>
              <option value="MV">Maldives</option>
              <option value="MW">Malawi</option>
              <option value="MX">Mexico</option>
              <option value="MY">Malaysia</option>
              <option value="MZ">Mozambique</option>
              <option value="NA">Namibia</option>
              <option value="NC">New Caledonia</option>
              <option value="NE">Niger</option>
              <option value="NF">Norfolk Island</option>
              <option value="NG">Nigeria</option>
              <option value="NI">Nicaragua</option>
              <option value="NL">Netherlands</option>
              <option value="NO">Norway</option>
              <option value="NP">Nepal</option>
              <option value="NR">Nauru</option>
              <option value="NU">Niue</option>
              <option value="NZ">New Zealand</option>
              <option value="OM">Oman</option>
              <option value="PA">Panama</option>
              <option value="PE">Peru</option>
              <option value="PF">French Polynesia</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PH">Philippines</option>
              <option value="PK">Pakistan</option>
              <option value="PL">Poland</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="PN">Pitcairn</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PT">Portugal</option>
              <option value="PY">Paraguay</option>
              <option value="QA">Qatar</option>
              <option value="RE">Reunion</option>
              <option value="RO">Romania</option>
              <option value="RS">Serbia</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SC">Seychelles</option>
              <option value="SD">Sudan</option>
              <option value="SE">Sweden</option>
              <option value="SG">Singapore</option>
              <option value="SH">
                Saint Helena, Ascension and Tristan da Cunha
              </option>
              <option value="SI">Slovenia</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SK">Slovakia</option>
              <option value="SL">Sierra Leone</option>
              <option value="SM">San Marino</option>
              <option value="SN">Senegal</option>
              <option value="SO">Somalia</option>
              <option value="SR">Suriname</option>
              <option value="SS">South Sudan</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SV">El Salvador</option>
              <option value="SX">Sint Maarten (Dutch part)</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="SZ">Swaziland</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TD">Chad</option>
              <option value="TF">French Southern Territories</option>
              <option value="TG">Togo</option>
              <option value="TH">Thailand</option>
              <option value="TJ">Tajikistan</option>
              <option value="TK">Tokelau</option>
              <option value="TL">Timor-Leste</option>
              <option value="TM">Turkmenistan</option>
              <option value="TN">Tunisia</option>
              <option value="TO">Tonga</option>
              <option value="TR">Turkey</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TV">Tuvalu</option>
              <option value="TW">Chinese Taipei</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="UA">Ukraine</option>
              <option value="UG">Uganda</option>
              <option value="US">United States</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="VE">Venezuela, Bolivarian Republic of</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VN">Viet Nam</option>
              <option value="VU">Vanuatu</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="WS">Samoa</option>
              <option value="YE">Yemen</option>
              <option value="YT">Mayotte</option>
              <option value="ZA">South Africa</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
          <div className={`input-checkbox ${formStyles.inputCheckbox}`}>
            <label>
              <input
                checked={this.state.receiveInformationEmails}
                onChange={this.handleInputChange}
                id="receiveInformationEmails"
                name="receiveInformationEmails"
                type="checkbox"
              />
              <span>
                Yes, I would like to receive information from Yara and Agoro
                Carbon Alliance about its products and services that may be
                helpful for me and my crops.
              </span>
            </label>
          </div>
          <div className={`input-checkbox ${formStyles.inputCheckbox}`}>
            <label>
              <input type="checkbox" required />
              <span>
                I have read and agreed to the Yara Privacy Policy. To find out
                what personal data we collect and how we use it, please visit
                our{" "}
                <a
                  href="https://www.yara.com/privacy-and-legal/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Yara Privacy Policy Page
                </a>
                .
              </span>
            </label>
          </div>
          <button
            type="submit"
            className={`btn btn--primary form-submit ${formStyles.formSubmit}`}
          >
            Get Started
            <Arrow
              width={22}
              height={20}
              aria-hidden="true"
              focusable="false"
            />
          </button>
        </form>
      </div>
    );
  }
}

export default FormPartners;
