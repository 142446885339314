import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import FormPartners from "../components/form-partners";
import Seo from "../components/seo";

const PartnersPage = () => (
  <Layout>
    <Seo
      title="Partner Network"
      description="Get access to certified carbon credits for your business through the Agoro Carbon Alliance."
    />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-partners.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="two farmers in a field looking at a clipboard"
        />
      </div>
      <div className="headline">
        <h1>
          Farm-based
          <br />
          Carbon Solutions
        </h1>
      </div>
      <div className="article-wrapper flow">
        <p>
          Backed by Yara and more than 115 years of proven crop nutrition
          experience, the Agoro Carbon&trade; Alliance has the global reach,
          local relationships and technological solutions to streamline the
          carbon credit process for companies across the globe to help meet
          their carbon emission goals.
        </p>
        <p>
          Our carbon-cropping model places farmers at the center of the
          solution, working with them side by side to implement the practices
          and processes that ensure high-quality carbon credits through
          climate-smart production.
        </p>
        <p>
          But we know that tackling the growing climate crisis will take both
          coordination and cooperation on a global level from partners across
          industries.
        </p>
        <p>
          Join us in creating a global shift in farming that enables the
          decarbonization of food production while providing farm-based carbon
          solutions for everyone.
        </p>
        <FormPartners />
      </div>
    </article>
  </Layout>
);

export default PartnersPage;
